import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogSkeletonLoader from "./BlogSkeletonLoader";

const generateSkeletonColumns = (count) => {
  const skeletonColumns = [];
  for (let i = 0; i < count; i++) {
    skeletonColumns.push(
      <Col key={i} lg={4} xs={12} className="d-flex justify-content-center">
        <div className="ml-5 bg-white mb-2 text-center" style={{ borderRadius: "20px" }}>
          <BlogSkeletonLoader />
        </div>
      </Col>
    );
  }
  return skeletonColumns;
};


const TestimonyCard = ({ isLoading, nameClient, imageClient, imageCover, textClient }) => {
  const [showFullText, setShowFullText] = useState(false);

  if (isLoading) {
    return <Row>{generateSkeletonColumns(3)}</Row>;
  }

  // Function to toggle showing the full legend
  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  // Truncate the legend if it exceeds 100 characters
  const truncatedText = textClient.length > 100 ? textClient.slice(0, 100) + " . . .  " : textClient;

  return (
    <Col xs={12} md={4} className="mb-2">
      <Container className="custom-testimony-banner">
        <Row className="custom-testimony-card">
          <Col xs={2} className="d-flex d-flex justify-content-center">
            <img className="border rounded-circle custom-testimony-avatar" src={imageClient} alt="avatar" />
          </Col>
          <Col xs={8} className="d-flex align-items-end justify-content-start">
            <h5 style={{ color: '#d19429', fontWeight: 'bold' }}>{nameClient}</h5>
          </Col>
        </Row>
        <Row className="custom-testimony-card">
          <Col xs={12}>
            <p
              className="custom-home-p"
            >
              {showFullText ? (
                <>{textClient}</>
              ) : (
                <>{truncatedText}</>
              )}
              {textClient.length > 100 && (
                <button className="custom-testimony-button" onClick={toggleShowFullText} >
                  {showFullText ? "  Lire moins" : " Afficher la suite"}
                </button>
              )}

            </p>
          </Col>
          <Col xs={12}>
            <img className="custom-testimony-card-image" src={imageCover} alt="card" />
          </Col>
          {/* <Col xs={12}>
            <img className="custom-testimony-card-image-min" src={imageClient} alt="card"/>
            <img className="custom-testimony-card-image-min" src={imageClient} alt="card" />
            <img className="custom-testimony-card-image-min" src={imageClient} alt="card" />
          </Col> */}
        </Row>
      </Container>
    </Col>
  );
}

export default TestimonyCard;
