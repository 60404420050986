import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import axios from 'axios';

import {
    TitlePage,
    Footer,
    GalleryCardBuilding,
    SectionParagraphWithBackground,
} from '../../components';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ServiceBuilding = () => {
    const [galleries, setGalleries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/building/api/modelrealize/active/?page=${page}`);
            const data = response.data.results;

            // Check for unique IDs directly in filteredData
            const filteredData = data.filter((newGallery) => !galleries.some((existingGallery) => existingGallery.id === newGallery.id));

            setGalleries(filteredData);
            setPage((prevPage) => prevPage + 1);

        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Stop fetching data if the API returns a 404 error
                setHasMore(false);
            } else {
                console.error('Error fetching data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [page, galleries]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            <TitlePage title={"Service bâtiment"} />
            <SectionParagraphWithBackground
                image="images/fond-service-building.jpeg"
                title1="4A'S"
                title2="Bâtiment travaux publics"
                paragraph={"Le travail du domaine du bâtiment et travaux publics est un domaine qui passionne l’équipe. Cela nous a pris du temps de nous former tant sur le domaine de la main d’œuvre que sur les respects des conformités afin de respecter les besoins de nos clients."}
            />
            <section className="mt-5 mx-sm-5 mx-1">
                <Row>
                    <Col xs={12} className="d-flex">
                        <h5 className="d-flex custom-title6 pt-5"
                            style={{ color: '#d19429' }}
                        >
                            ACTIVITÉS
                        </h5>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col>
                        <p className="custom-p-style">La construction en bâtiment est un upgrade de ses services.
                            4A’S BTP œuvre dans les différentes étapes susceptibles d’appartenir à un bâtiment : de la conception d’un plan 3D, à l’émission de devis quantitatif jusqu’à l’exécution des travaux en lui-même. Nous pouvons également réaliser une réparation ou finition d’un travail.</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="d-flex mt-5">
                        <h5 className="d-flex custom-title6 pt-2"
                            style={{ color: '#d19429' }}
                        >
                            COLLABORATEURS
                        </h5>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col>
                        <p className="pb-2 custom-p-style">Nous travaillons avec les particuliers pour une maison d’habitation, un lotissement ou encore un appartement. Les professionnels de l’hôtellerie mais également les entreprises à activités sensible telles que les stations-services. Nos exigences restent les mêmes pour tous nos collaborateurs dans la capitale et en province.</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="d-flex mt-5">
                        <h5 className="d-flex custom-title6 pt-2"
                            style={{ color: '#d19429' }}
                        >
                            NORME HSSE ET CONFORMITÉ
                        </h5>
                    </Col>
                    <Col><hr /></Col>
                </Row>
                <Row>
                    <Col>
                        <p className="pb-2 custom-p-style">
                            La satisfaction clientèle et la sécurité de nos employés sont au cœur de notre preaucupation à cet effet nous  répondons aux exigences HSSE.
                            Chaque personnel membre des équipes est au centre de notre activité. Leur protection se trouve être notre priorité. Pour cela, nous agissons avec des disciplines et une gamme d’EPI suivant les normes : lunettes, casque, gilet, extincteur.
                            Chaque chantier doit être un lieu de sécurité pour tous.
                        </p>
                    </Col>
                </Row>
            </section >
            <section style={{ overflowX: 'hidden' }}>
                <InfiniteScroll
                    dataLength={galleries.length}
                    next={() => setPage(page + 1)}
                    hasMore={hasMore}
                    loader={<GalleryCardBuilding isLoading />}
                    style={{ overflowX: 'hidden' }}
                >
                    <Row className="custom-gallery-row">
                        {galleries.slice(-3).map((gallery) => (
                            <GalleryCardBuilding
                                isLoading={isLoading}
                                idBuilding={gallery.id}
                                imageBuilding={gallery.image}
                                nameBuilding={gallery.name}
                                priceBuilding={gallery.price}
                                descriptionBuilding={gallery.description}
                            />
                        ))}
                    </Row>
                </InfiniteScroll>
            </section>
            {galleries.length > 0 &&
                <Row>
                    <Col className="d-flex justify-content-center">
                        <a
                            href="/bâtiment/galerie"
                            className="text-decoration-none custom-service-a"
                        >
                            Voir plus
                        </a>
                    </Col>
                </Row>
            }
            <Footer />
        </>
    );
};

export default ServiceBuilding;