import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import axios from 'axios';

import {
    TitlePage,
    Footer,
    BlogCard,
    FormatDate,
} from '../../components';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Blog = () => {
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/blog/api/articles/active/?page=${page}`);
            const data = response.data.results;

            // Check for unique IDs directly in filteredData
            const filteredData = data.filter((newBlog) => !blogs.some((existingBlog) => existingBlog.id === newBlog.id));

            setBlogs(filteredData);
            setPage((prevPage) => prevPage + 1);

        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Stop fetching data if the API returns a 404 error
                setHasMore(false);
            } else {
                console.error('Error fetching data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [page, blogs]);


    useEffect(() => {
        fetchData();

    }, [fetchData]);

    return (
        <>
            <TitlePage title={"Blog"} />
            <Row style={{ background: 'url("images/fond-blog.jpg") no-repeat' }}>
                <Col className="d-flex align-items-end mt-5 custom-fond-contact">
                    <h1 className='custom-h-contact'>Blog et actualités 4A'S</h1>
                </Col>
            </Row>
            <section className='mx-sm-5 mx-3 mt-3 mb-4' style={{ overflowX: 'hidden' }}>
                <Row className="d-flex">
                    <Col xs={12}>
                        <p className="custom-p-V2">
                        Préparez votre piscine pour les grandes vacances. Imaginez dès maintenant et réalisons dès la fin de la saison de pluie. La durée des travaux varie de 30 à 45 jours, pour un entretien de choc et une belle eau translucide, prenez un rendez-vous.
                        </p>
                    </Col>
                    <Col xs={12}><hr className='custom-hr' /></Col>
                </Row>
            </section>
            <section className='mx-sm-5 mt-2' style={{ overflowX: 'hidden' }}>
                <InfiniteScroll
                    dataLength={blogs.length}
                    next={() => setPage(page + 1)}
                    hasMore={hasMore}
                    loader={<BlogCard isLoading />}
                    style={{ overflowX: 'hidden' }}
                >
                    <Row className="d-flex" style={{ overflowX: 'hidden' }}>
                        {blogs.map((blog) => (
                            <BlogCard
                                key={blog.id}
                                isLoading={isLoading}
                                titleBlog={blog.title}
                                dateBlog={FormatDate(blog.pub_date)}
                                legendBlog={blog.legend}
                                imageBlog={blog.image}
                                textBlog={blog.content}
                            />
                        ))}
                    </Row>
                </InfiniteScroll>
            </section>
            <Footer />
        </>
    );
};

export default Blog;
