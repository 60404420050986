import React from "react";
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import GalleryCardPoolSkeletonLoader from "./GalleryCardPoolSkeletonLoader";

const generateSkeletonColumns = (count) => {
    const skeletonColumns = [];
    for (let i = 0; i < count; i++) {
        skeletonColumns.push(
            <Col key={i} lg={4} xs={12} className="d-flex justify-content-center">
                <div className="ml-5 bg-white mb-2 text-center" style={{ borderRadius: "15px" }}>
                    <GalleryCardPoolSkeletonLoader />
                </div>
            </Col>
        );
    }
    return skeletonColumns;
};

const GalleryCardPool = ({ isLoading, idPool, imagePool, namePool, pricePool, descriptionPool }) => {

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    if (isLoading) {
        return <Row>{generateSkeletonColumns(3)}</Row>;
    }

    return (
        <Col key={idPool} xs={12} sm={4} className="col-sm-4 custom-gallery-col custom-gallery-col-2" id={`pool${idPool}`} style={{ marginRight: '-7px', marginLeft: '-7px' }}>
            <div className="content">
                <h4 className="custom-gallery-h4" style={{ display: 'block' }}>
                    <strong>{namePool}</strong><br/>
                    <strong className="custom-gallery-price-2">{formatPrice(pricePool)} AR TTC</strong> <br />
                </h4>
                <p className="custom-gallery-description" style={{ display: 'block' }}>
                    <br/>{descriptionPool} <br />
                    <Link className="text-decoration-none" to={`/piscine/devis/${idPool}`}>
                        <span className="custom-gallery-a">Plus d'information</span>
                    </Link>
                </p>
            </div>
            <img className="custom-gallery-img" src={imagePool} alt={namePool} style={{ width: '110%' }} />
        </Col>
    );
};

export default GalleryCardPool;
