import React from 'react';
import ContentLoader from 'react-content-loader';

const GalleryCardBuildingSkeletonLoader = () => (
  <ContentLoader
    speed={2}
    width={350}
    height={400}
    viewBox="0 0 350 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="5" y="5" rx="10" ry="10" width="340" height="300" />
    <rect x="5" y="310" rx="3" ry="3" width="200" height="20" />
    <rect x="5" y="335" rx="3" ry="3" width="100" height="10" />
    <rect x="5" y="360" rx="3" ry="3" width="200" height="20" />
    <rect x="5" y="385" rx="3" ry="3" width="100" height="10" />
  </ContentLoader>
);

export default GalleryCardBuildingSkeletonLoader;
