import React, { useState } from 'react';
import axios from 'axios';

import { Row, Col, Button, Form } from "react-bootstrap";

import { ToastContainer, toast } from 'react-toastify';


import {
    TitlePage,
    ContactCard,
    Footer,
} from '../../components';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Contact = () => {
    const [formData, setFormData] = useState({
        name_client: '',
        phone_client: '',
        email_client: '',
        location_client: '',
        message_client: '',
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await axios.post(`${BASE_URL}/quatreasinfo/api/mailinbox/create/`, formData);
            console.log('MailInBox created:', response.data);
            toast.success('Mail envoyé', {
                autoClose: 3000, // Close the notification after 3 seconds (adjust as needed)
                position: toast.POSITION.TOP_CENTER,
            });
            setFormData({
                name_client: '',
                phone_client: '',
                email_client: '',
                location_client: '',
                message_client: '',
            });
        } catch (error) {
            console.error('Error creating MailInBox:', error);
            toast.error("Un problème s'est produit, merci de réessayer plus tard", {
                autoClose: 3000, // Close the notification after 3 seconds (adjust as needed)
                position: toast.POSITION.TOP_CENTER,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-image-container">
            <TitlePage title={"Contact"} />
            <Row style={{ background: 'url("images/fond-contact.jpg") no-repeat' }}>
                <Col className="d-flex align-items-end mt-5 custom-fond-contact">
                    <h1 className='custom-h-contact'>CONTACT</h1>
                </Col>
            </Row>
            <section className="custom-contact-div mt-2 mx-sm-5 mx-1">
                <div id="formulaire" className="formulaire-contact mt-5 mb-5">
                    <Row>
                        <Col sm={12} md={12} lg={5}>
                            <h4 className="custom-title-contact pt-1">Nos équipes se tiennent à votre disposition pour répondre à vos questions</h4>
                        </Col>
                        <Col sm={12} md={12} lg={7}>
                            <Form onSubmit={handleSubmit} className="form-formulaire-contact">
                                {/* Name Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formName">
                                    <Form.Label column sm="3" xs="12">Nom complet</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='name_client' value={formData.name_client} onChange={handleChange} type="text" placeholder="" required />
                                    </Col>
                                </Form.Group>

                                {/* Phone Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formPhone">
                                    <Form.Label column sm="3" xs="12">Téléphone</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='phone_client' value={formData.phone_client} onChange={handleChange} type="text" placeholder="" required />
                                    </Col>
                                </Form.Group>

                                {/* Email Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formEmail">
                                    <Form.Label column sm="3" xs="12">Email</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='email_client' value={formData.email_client} onChange={handleChange} type="email" placeholder="" required />
                                    </Col>
                                </Form.Group>

                                {/* Location Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formLocation">
                                    <Form.Label column sm="3" xs="12">Lieu</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='location_client' value={formData.location_client} onChange={handleChange} type="text" placeholder="" required />
                                    </Col>
                                </Form.Group>

                                {/* Message Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formMessage">
                                    <Form.Label column sm="3" xs="12">Message</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='message_client' value={formData.message_client} onChange={handleChange} as="textarea" rows={5} placeholder="" required />
                                    </Col>
                                </Form.Group>

                                {/* Submit Button */}
                                <Row>
                                    <Col sm={3} xs={12}></Col>
                                    <Col sm={9} xs={12}>
                                        <Button className="custom-contact-button" type="submit" disabled={loading}>
                                            {loading ? 'Envoi en cours...' : 'Envoyer'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </section>
            <section>
                <ContactCard />
            </section>
            <ToastContainer />
            <Footer />
        </div>
    );
}

export default Contact;
