import { Col, Row } from 'react-bootstrap';

const ProfileCard = ({name, image, role}) => {
    return (
        <Col xs={6} sm={6} md={6} lg={3} className=''>
            <Row>
                <Col className="d-flex">
                    <img className='custom-profil-image-card' src={image} alt="profile" />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="d-flex">
                    <p className="mt-3 custom-card-name-profil">{ name }</p>
                </Col>
                <Col xs={12} className="d-flex">
                    <p className="poste">{ role }</p>
                </Col>
            </Row>
        </Col>
    )
}

export default ProfileCard;