import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Row, Col, Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';

import {
    TitlePage,
    Footer,
    ContactCard,
    FormatNumber,
} from '../../components';

const dropzoneStyles = (isDragActive, isDragReject) => ({
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: isDragActive ? '#eeeeee' : 'white',
    color: isDragReject ? 'red' : 'inherit',
});

// Function to format the date as "AAAAMMDDHMSS"
const getFormattedDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

const BASE_URL = process.env.REACT_APP_BASE_URL;

const QuoteBuilding = () => {
    const { id: IDmodelChosenIf } = useParams(); // Get the 'id' parameter from the userSelect:

    const [modelChosen, setModelChosen] = useState();
    const [creditConfig, setCreditConfig] = useState([]);

    // Initialize date
    const name_model_applied = "DEVISBUILDING" + getFormattedDate();

    const [uploadedFile, setUploadedFile] = useState(null);

    // Update fetchData to set image_uploaded in formData
    const fetchData = useCallback(async () => {
        if (IDmodelChosenIf !== 'none') {
            try {
                const response = await axios.get(`${BASE_URL}/building/api/modelrealize/${IDmodelChosenIf}/`);
                const chosenModel = response.data;

                setModelChosen(chosenModel);

                // Download the image and convert it to a file
                const imageResponse = await fetch(chosenModel.image);
                const imageBlob = await imageResponse.blob();
                const imageFile = new File([imageBlob], 'chosenModelImage.jpg', { type: 'image/jpeg' });

                // Set image_uploaded in formData if there's no uploaded file from dropzone
                if (!uploadedFile) {
                    setUploadedFile(imageFile);
                    setFormData((prevData) => ({
                        ...prevData,
                        image_uploaded: imageFile,
                    }));
                }
            } catch (error) {
                console.error('Error checking the model chosen:', error);
            }
        }
    }, [IDmodelChosenIf, uploadedFile]);

    const fetchDataBuilding = useCallback(async () => {
        try {
            const list_credit_configs = await axios.get(`${BASE_URL}/pool/api/active-credit-config/`);

            setCreditConfig(list_credit_configs.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }, []);


    // Update onDrop to handle the case when no file is selected
    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        setUploadedFile(file);

        // Set image_uploaded in formData only if there's no modelChosen
        if (!modelChosen) {
            setFormData((prevData) => ({
                ...prevData,
                image_uploaded: file,
            }));
        }
    }, [modelChosen]);

    // Update handleChange to handle changes in image_uploaded
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        const fieldValue = type === 'file' ? files[0] : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: fieldValue,
        }));

        // Handle special cases for interest_rate, pct_amount_of_first_part_of_cash, and number_of_months
        if (name === 'credit_index') {
            // Handle changes for interest_rate
            setFormData((prevData) => ({
                ...prevData,
                [name]: fieldValue,
                interest_rate: creditConfig[fieldValue]?.interest_rate || null,
                pct_amount_of_first_part_of_cash: creditConfig[fieldValue]?.pct_amount_of_first_part_of_cash || null,
                number_of_months: creditConfig[fieldValue]?.number_of_months || null,
            }));
        } else if (name === 'payment_mode' && value === "Cash") {
            // Handle changes for payment_mode
            setFormData((prevData) => ({
                ...prevData,
                [name]: fieldValue,
                interest_rate: null,
                pct_amount_of_first_part_of_cash: null,
                number_of_months: null,
            }));
        } else if (name === 'payment_mode' && value === "Credit") {
            // Handle changes for payment_mode
            setFormData((prevData) => ({
                ...prevData,
                [name]: fieldValue,
                interest_rate: creditConfig[0]?.interest_rate || null,
                pct_amount_of_first_part_of_cash: creditConfig[0]?.pct_amount_of_first_part_of_cash || null,
                number_of_months: creditConfig[0]?.number_of_months || null,
            }));
        }
    };

    const [formData, setFormData] = useState({
        name_client: '',
        phone_client: '',
        email_client: '',
        location_client: '',
        name_model: modelChosen?.name,
        payment_mode: '',  // Initialize payment_mode
        message_client: '',
        duration: modelChosen?.duration,
        price: modelChosen?.price,
        note: '',
        image_uploaded: modelChosen?.image,
        interest_rate: creditConfig[0]?.interest_rate || null,
        pct_amount_of_first_part_of_cash: creditConfig[0]?.pct_amount_of_first_part_of_cash || null,
        number_of_months: creditConfig[0]?.number_of_months || null,
        credit_index: 0,
    });

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/jpg, image/png',
        maxFiles: 1,
        maxSize: 1024 * 1024, // 500 KB
    });

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        console.log(formData);
        e.preventDefault();
        try {
            setLoading(true);

            const response = await axios.post(`${BASE_URL}/building/api/modelrequested/`, {
                ...formData,  // Use all fields from formData
                name_model: modelChosen?.name || name_model_applied,  // Use name_model from formData if available, otherwise use name from modelChosen
                duration: modelChosen?.duration,
                price: modelChosen?.price,
            }, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            console.log('MailInBox created:', response.data);
            toast.success('Demande de devis est envoyée', {
                autoClose: 3000, // Close the notification after 3 seconds (adjust as needed)
                position: toast.POSITION.TOP_CENTER,
            });
            setFormData({
                name_client: '',
                phone_client: '',
                email_client: '',
                location_client: '',
                name_model: '',
                payment_mode: '',
                message_client: '',
                duration: null,
                price: null,
                note: '',
                image_uploaded: null,
                interest_rate: null,
                pct_amount_of_first_part_of_cash: null,
                number_of_months: null,
                credit_index: 0,
            });
        } catch (error) {
            console.error('Error creating MailInBox:', error);
            toast.error("Un problème s'est produit, merci de réessayer plus tard", {
                autoClose: 3000, // Close the notification after 3 seconds (adjust as needed)
                position: toast.POSITION.TOP_CENTER,
            });
        } finally {
            setLoading(false);
        }
    };

    /* function to calculate total amount */
    function calculateTotalAmount() {
        const price = modelChosen?.price || 0;
        const pctAmountOfFirstPartOfCash = formData.pct_amount_of_first_part_of_cash || creditConfig[0]?.pct_amount_of_first_part_of_cash;
        const interestRate = formData.interest_rate || creditConfig[0]?.interest_rate;

        const downPayment = (pctAmountOfFirstPartOfCash * price) / 100;
        const creditedAmount = price - downPayment;

        // Calculate total amount with interest
        const totalAmount = downPayment + creditedAmount * (1 + interestRate / 100);

        return totalAmount;
    }

    function getFirstTrancheAmount() {
        return ((modelChosen?.price || 0) * formData.pct_amount_of_first_part_of_cash) / 100;
    }

    function getMonthlyPayment() {
        return (calculateTotalAmount() - getFirstTrancheAmount()) / formData.number_of_months;
    }


    useEffect(() => {
        fetchData();
        fetchDataBuilding();
    }, [fetchData, fetchDataBuilding]);

    return (
        <>
            <TitlePage title={"Devis bâtiment"} />
            <Row style={{ background: 'url("images/fond-50.jpg") no-repeat' }}>
                <Col className="d-flex align-items-end mt-5 custom-fond-contact">
                    <h1 className='custom-h-contact'>DEVIS BÂTIMENT</h1>
                </Col>
            </Row>
            <section className='mx-sm-5 mx-1 mt-5 mb-4' style={{ overflowX: 'hidden' }}>
                <div id="formulaire" className="formulaire-contact mb-4">
                    <Row>
                        <Col sm={12} md={12} lg={5} >
                            <h2 className="custom-title-contact pt-1">
                                Nos équipes se tiennent à votre disposition pour répondre à vos questions
                            </h2>
                            <Row>
                                <Col className="d-flex justify-content-center pt-4 pb-4">
                                    {modelChosen ?
                                        <>
                                            <img name="image_uploaded" className="custom-quote-image d-flex" src={modelChosen.image} alt={"batiment"} />
                                        </>
                                        :
                                        <>
                                            <div>
                                                {uploadedFile && (
                                                    <div>
                                                        <img src={URL.createObjectURL(uploadedFile)} alt="Uploaded" style={{ maxWidth: "450px", height: 'auto' }} />
                                                    </div>
                                                )}
                                                <div {...getRootProps()} style={dropzoneStyles(isDragActive, isDragReject)}>
                                                    <input name='image_uploaded' {...getInputProps()} />
                                                    <p className='custom-quote-p'>Glissez-déposez un visuel ici, ou sélectionnez-en un en cliquant dessus ( Taille maximale : 500 ko ).</p>
                                                </div>
                                            </div>
                                            {/* <img className="custom-quote-image d-flex justify-content-center" src="images/image_to_upload.png" alt={"batiment"} /> */}
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={12} lg={7}>
                            <Form onSubmit={handleSubmit} className="form-formulaire-contact">
                                {/* Name Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formName">
                                    <Form.Label column sm="3" xs="12">Nom complet</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='name_client' value={formData.name_client} onChange={handleChange} type="text" placeholder="" required />
                                    </Col>
                                </Form.Group>

                                {/* Phone Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formPhone">
                                    <Form.Label column sm="3" xs="12">Téléphone</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='phone_client' value={formData.phone_client} onChange={handleChange} type="text" placeholder="" required />
                                    </Col>
                                </Form.Group>

                                {/* Email Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formEmail">
                                    <Form.Label column sm="3" xs="12">Email</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='email_client' value={formData.email_client} onChange={handleChange} type="email" placeholder="" required />
                                    </Col>
                                </Form.Group>

                                {/* Location Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formLocation">
                                    <Form.Label column sm="3" xs="12">Lieu</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='location_client' value={formData.location_client} onChange={handleChange} type="text" placeholder="" required />
                                    </Col>
                                </Form.Group>

                                {/* Service Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formService">
                                    <Form.Label column sm="3" xs="12">Service</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='service' value={"Bâtiment"} type="text" disabled />
                                    </Col>
                                </Form.Group>
                                {modelChosen && <>
                                    <Form.Group as={Row} className="mb-3" controlId="formModelChosen">
                                        <Form.Label column sm="3" xs="12">Model choisi</Form.Label>
                                        <Col sm={9} xs={12}>
                                            <Form.Control name='name_model' value={modelChosen?.name} onChange={handleChange} type="text" disabled />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formModelChosen">
                                        <Form.Label column sm="3" xs="12">Prix</Form.Label>
                                        <Col sm={9} xs={12}>
                                            <Form.Control name='price' value={FormatNumber(modelChosen?.price) + " AR TTC"} onChange={handleChange} type="text" disabled />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formModelChosen">
                                        <Form.Label column sm="3" xs="12">Duration</Form.Label>
                                        <Col sm={9} xs={12}>
                                            <Form.Control name='duration' value={modelChosen?.duration} onChange={handleChange} type="text" disabled />
                                        </Col>
                                    </Form.Group></>
                                }
                                {/* Payment mode Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formPaymentMode">
                                    <Form.Label column sm="3" xs="12">Mode paiement</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Select
                                            name='payment_mode'
                                            value={formData.payment_mode}
                                            onChange={handleChange}
                                            aria-label="Payment mode"
                                            required
                                        >
                                            <option value="Cash">Au comptant</option>
                                            <option value="Credit">A crédit</option>
                                        </Form.Select>
                                    </Col>
                                </Form.Group>

                                {/* Repayment Period and Interest Rate */}
                                {formData.payment_mode === "Credit" && (
                                    <Form.Group as={Row} className="mb-3" controlId="formRepaymentPeriod">
                                        <Form.Label column sm="3" xs="12">Echéance</Form.Label>
                                        {creditConfig.map((credit, index) => (
                                            <Col key={index} xs={6} sm={3}>
                                                <Form.Check
                                                    type="radio"
                                                    label={credit.title}
                                                    name="credit_index"
                                                    value={index}
                                                    onChange={handleChange}
                                                    defaultChecked={index === 0 ? true : false}
                                                />
                                            </Col>
                                        ))}

                                        {/* Rate Field */}
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm="3" xs="12">Taux d'intérêt</Form.Label>
                                            <Col sm={9} xs={12}>
                                                <Form.Control name='interest_rate' value={formData.interest_rate + ' %'} type="text" disabled />
                                            </Col>
                                        </Form.Group>
                                    </Form.Group>
                                )}

                                {/* Display Total Amount to be Paid */}
                                {modelChosen && formData.payment_mode === "Credit" && (
                                    <>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm="3" xs="12">1ère tranche {formData.pct_amount_of_first_part_of_cash}% :</Form.Label>
                                            <Col sm={9} xs={12}>
                                                <Form.Control value={FormatNumber(getFirstTrancheAmount()) + " AR TTC"} type="text" disabled />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm="3" xs="12">Traite par mois:</Form.Label>
                                            <Col sm={9} xs={12}>
                                                <Form.Control value={FormatNumber(getMonthlyPayment()) + " AR TTC"} type="text" disabled />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm="3" xs="12">Total à payer:</Form.Label>
                                            <Col sm={9} xs={12}>
                                                <Form.Control value={FormatNumber(calculateTotalAmount()) + " AR TTC"} type="text" disabled />
                                            </Col>
                                        </Form.Group>
                                    </>
                                )}

                                {/* Message Field */}
                                <Form.Group as={Row} className="mb-3" controlId="formMessage">
                                    <Form.Label column sm="3" xs="12">Note</Form.Label>
                                    <Col sm={9} xs={12}>
                                        <Form.Control name='message_client' value={formData.message_client} onChange={handleChange} as="textarea" rows={5} placeholder="" required />
                                    </Col>
                                </Form.Group>

                                {/* Submit Button */}
                                <Row>
                                    <Col sm={3} xs={12}></Col>
                                    <Col sm={9} xs={12}>
                                        <Button className="custom-contact-button" type="submit" disabled={loading}>
                                            {loading ? 'Envoi en cours...' : 'Envoyer'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </section>
            <section>
                <ContactCard />
            </section>
            <ToastContainer />
            <Footer />
        </>
    );
};

export default QuoteBuilding;