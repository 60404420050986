import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// External dependencies
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-toastify/dist/ReactToastify.css';

// Internal imports
import { NavbarMenu } from './components';
import { 
  Home,
  Contact,
  Whoarewe,
  Blog,
  GalleryPool,
  ServicePool,
  OurMoreOffers,
  QuotePool,
  GalleryBuilding,
  ServiceBuilding,
  QuoteBuilding,
  NotFound
} from './pages';

import './custom.css';
import './custom.js';


function App() {
  return (
    <Router>
      <div>
        <NavbarMenu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/QuiNousSommes" element={<Whoarewe />} />
          <Route path="/Blog&Actualites" element={<Blog />} />
          <Route path="/piscine/galerie" element={<GalleryPool />} />
          <Route path="/piscine/service" element={<ServicePool />} />
          <Route path="/piscine/devis/:id" element={<QuotePool />} />
          <Route path="/piscine/Nos-offres-en-plus" element={<OurMoreOffers />} />
          <Route path="/bâtiment/service" element={<ServiceBuilding />} />
          <Route path="/bâtiment/devis/:id" element={<QuoteBuilding />} />
          <Route path="/bâtiment/galerie" element={<GalleryBuilding />} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
