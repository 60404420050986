import React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  TitlePage,
  Footer,
} from '../../components';

const OurMoreOffers = () => {
  return (
    <>
      <TitlePage title={"Nos offres plus"} />
      {/* <Row style={{ background: 'url("images/fond-5.jpg")' }}>
        <Col className="d-flex align-items-end custom-fond-contact">
          <h1 className='custom-h-contact'>NOS OFFRES EN PLUS</h1>
        </Col>
      </Row> */}
      <Row className="custom-gallery-row pool-our-more-offers-row">
        <Col className="custom-gallery-col pool-our-more-offers-col" id="col1" style={{ marginRight: '-7px', marginLeft: '-7px' }}>
          <div className="content">
            <h4 className="custom-gallery-h4 pool-our-more-offers" style={{ display: 'block' }}>
              <strong>EQUIPER</strong>
            </h4>
            <p className="custom-gallery-description pool-our-more-offers" style={{ display: 'block' }}>
              Nous mettons à votre disposition des matériels pour le "plus" de votre piscine : une plage, des lumières colorées, une pompe à chaleur, des galets. Inspirez-vous et faites-nous confiance.
            </p>
          </div>
          <img className="custom-gallery-img" src="images/equiper.jpg" alt="Equiper" style={{ width: '110%' }} />
        </Col>
        <Col className="custom-gallery-col pool-our-more-offers-col" id="col2" style={{ marginRight: '-7px', marginLeft: '-7px' }}>
          <div className="text-start d-xl-flex justify-content-xl-start content">
            <h4 className="custom-gallery-h4 pool-our-more-offers">
              <strong>ENTRETENIR</strong>
            </h4>
            <p className="custom-gallery-description pool-our-more-offers">
              Une piscine en bonne santé requiert un entretien périodique. Cela dépend du climat et de sa fréquentation. Nous proposons deux types d'entretien : un, pour une piscine qui n'a pas fonctionné de longue durée et deux, une piscine fréquentée périodiquement.
            </p>
          </div>
          <img className="custom-gallery-img" src="images/entretenir.jpg" alt="Entretenir" style={{ width: '110%' }} />
        </Col>
        <Col className="custom-gallery-col pool-our-more-offers-col" id="col3" style={{ marginRight: '-7px', marginLeft: '-7px' }}>
          <div className="content">
            <h4 className="custom-gallery-h4 pool-our-more-offers">
              <strong>RENOVER</strong>
            </h4>
            <p className="custom-gallery-description pool-our-more-offers">Pour devenir un game-changer africain nous devons répondre à des enjeux multiplex : la mutation de nos offres, de notre environnement, de notre organisation et des modes de consommation des populations du continent.</p>
          </div>
          <img className="custom-gallery-img" src="images/renover.jpg" alt="Rénover" style={{ width: '110%' }} />
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default OurMoreOffers;
