document.addEventListener('DOMContentLoaded', function () {
  const columns = document.querySelectorAll('.custom-gallery-col');

  columns.forEach(column => {
    column.addEventListener('mouseenter', function () {
      this.querySelector('.custom-gallery-description').style.visibility = 'visible';
    });

    column.addEventListener('mouseleave', function () {
      this.querySelector('.custom-gallery-description').style.visibility = 'hidden';
    });
  });
});
