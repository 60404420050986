import React from 'react';

import { Row, Col, Button } from 'react-bootstrap';

const SectionParagraphWithBackground = ({ title1, title2, paragraph, image, button }) => {
    return (
        <section className="h-100 position-relative">
            <Row className="h-100">
                <Col xs={12} className="">
                    {/* Image as background for the text content */}
                    <div
                        className="bg-image custom-fond-image"
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    >
                        <h4 className="home-custom-h2">{title1}</h4>
                        <h3 className="home-custom-h2-2">{title2}</h3>
                        <p className="mt-2 home-custom-p">
                            {paragraph}
                        </p>
                        {button &&
                            <Button className="custom-whoarewe-button" href="/Contact">Contactez-nous</Button>
                        }
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default SectionParagraphWithBackground;