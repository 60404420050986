import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="text-center bg-body-tertiary">
      <div className='custom-footer-bg'>
        <div className="row d-xl-flex d-xxl-flex justify-content-xl-center justify-content-xxl-center" style={{ background: 'rgba(227,227,227,0)' }}>
          <div className="col-xl-3 col-xxl-2 d-xl-flex d-xxl-flex justify-content-xl-end align-items-xl-end justify-content-xxl-end align-items-xxl-end mt-4">
            <p className="d-xxl-flex">LE LUXE A PETIT PRIX</p>
          </div>
          <div className="col-xl-3 col-xxl-3 d-xxl-flex justify-content-xxl-start">
            <section className="d-xl-flex justify-content-xl-start mt-3">
              <a href="https://www.facebook.com/4aspiscine?mibextid=LQQJ4d" target='_blank' rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} className="btn btn-link btn-lg btn-floating text-body m-1" />
              </a>
              <a href="https://www.instagram.com/4asgroup?igsh=enZ4ZTVnYzg5b2M5" target='_blank' rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="btn btn-link btn-lg btn-floating text-body m-1" />
              </a>
              <a href="https://www.linkedin.com/in/4a-s-group-4a-s-group-sarl-8442571b5/" target='_blank' rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className="btn btn-link btn-lg btn-floating text-body m-1" />
              </a>
  
              <a className="btn btn-link align-items-center btn-floating text-body m-1" role="button" href="/contact" data-mdb-ripple-color="dark">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-envelope">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"></path>
                </svg>
              </a>
            </section>
          </div>
        </div>
        <div className="text-center p-3" style={{ background: 'rgba(255,255,255,0)' }}>
          <span style={{ fontSize: '14px' }}> © 2024 Copyright&nbsp;</span>
          <a className="text-body" href="/Contact" style={{ fontSize: '14px' }}>by AREA Madagascar</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
