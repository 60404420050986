import React from "react";

import { Helmet } from 'react-helmet';

const TitlePage = ({ title }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
            <title> 4A'S | {title} </title>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800&amp;subset=cyrillic&amp;display=swap" />
        </Helmet>
    )
}

export default TitlePage;