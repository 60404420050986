import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import axios from 'axios';

import {
    TitlePage,
    Footer,
    ProfileCard,
    SectionParagraphWithBackground,
} from '../../components';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Whoarewe = () => {
    const currentYear = new Date().getFullYear();
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        const fetchTeamsData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/quatreasinfo/api/teams/active/`);
                setTeams(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchTeamsData();
    }, []);


    return (
        <>
            <TitlePage title={"Qui nous sommes"} />
            <SectionParagraphWithBackground
                image="images/nous.jpg"
                title1={"PLUS DE " + (currentYear - 2020) + " ANS D'EXPÉRIENCES"}
                title2={"4A'S GROUP"}
                button="true"
                paragraph={"4As group votre partenaire de confiance en bâtiment génie civile et construction de piscine sur mesure. Alliant luxe et normes européennes, notre équipe experte vous accompagne à chaque étape pour des projets réussis. Optez pour 4as Group et bénéficiez de solutions personnalisées et d'une excellence incomparable"}
            />
            <Col className="custom-col-whoarewe pt-5">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className="custom-whoarewe-title pb-0 pt-5" style={{ fontWeight: "bold", }}>
                                POURQUOI CHOISIR 4A'S
                            </h2>
                        </Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <p className='custom-p-style pb-4'>
                                Pour sa passion et son expertise : nous sommes une équipe de passionnés avec une expertise poussée dans chacun son domaine travaillant en synergie pour vous offrir le meilleur. Pour sa créativité : l’innovation est au centre de notre valeur. Nous cherchons toujours à aller au-delà de la demande de nos clientèles en vue de les surprendre. Pour sa compétitivité : nous soutenons notre devise, c'est-à-dire de rendre le luxe accessible pour nos épicuriens. Casser la barrière au luxe à travers des offres abordables tout en respectant les normes.
                                Avec la vente de nos services et produits, nous nous engageons à vous accompagner de nos recommandations et assistance afin que vous puissiez jouir avec quiétude de vos biens.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h2 className="custom-whoarewe-title pt-5 pb-0" style={{ fontWeight: "bold" }}> NOS FORCES</h2>
                        </Col>
                        <Col><hr /></Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <p className='custom-p-style pb-4'>
                                Héritant d’une équipe multidisciplinaire fortement expérimentée dans chacun de leur domaine, 4’AS s’appuie sur ces ressources pour apporter de l’innovation dans tous les secteurs où nous opérant tout en gardant au centre de notre préoccupation, la satisfaction de nos clients.
                                Rendre accessible le luxe à tout l’épicurien est notre devise !
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <section className='custom-col-whoarewe-2 pt-5'>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h2 className="custom-whoarewe-title pb-0" style={{ fontWeight: "bold" }}> EQUIPE</h2>
                        </Col>
                        <Col><hr /></Col>
                    </Row>
                    <p className='custom-p-style pb-1'>
                        Avec la vente de nos services et produits, nous nous engageons à vous accompagner de nos recommandation et assistance afin que vous puissiez jouir avec quietude de nos biens
                    </p>

                    <Row className="custom-card-img-profil">
                        {teams.map((team) => (
                            <ProfileCard key={team.id} name={team.full_name} image={team.image} role={team.role} />
                        ))}
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default Whoarewe;
