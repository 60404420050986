import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import BlogSkeletonLoader from "./BlogSkeletonLoader";

const generateSkeletonColumns = (count) => {
    const skeletonColumns = [];
    for (let i = 0; i < count; i++) {
        skeletonColumns.push(
            <Col key={i} lg={4} xs={12} className="d-flex justify-content-center">
                <div className="ml-5 bg-white mb-2 text-center" style={{ borderRadius: "15px" }}>
                    <BlogSkeletonLoader />
                </div>
            </Col>
        );
    }
    return skeletonColumns;
};

const BlogCard = ({ isLoading, titleBlog, imageBlog, dateBlog, legendBlog, textBlog }) => {
    const [showFullText, setShowFullText] = useState(false);

    if (isLoading) {
        return <Row>{generateSkeletonColumns(3)}</Row>;
    }

    // Function to toggle showing the full legend
    const toggleShowFullText = () => {
        setShowFullText(!showFullText);
    };

    // Truncate the legend if it exceeds 100 characters
    const truncatedText = textBlog.length > 100 ? textBlog.slice(0, 100) + " ..." : textBlog;

    return (
        <Col sm={8} md={7} lg={5} xl={4} className="ml-5 custom-blog-col">
            <div className="ml-5 p-3 bg-white mb-2" style={{ borderRadius: "15px" }}>
                <Row>
                    <Col md={12} className="d-flex justify-content-center">
                        <img className="custom-image-blog" src={imageBlog} alt={`blog`} />
                    </Col>
                </Row>
                <Row className="d-xl-flex align-items-xl-center mt-2">
                    <Col>
                        <p style={{ color: "#8d97ad" }}>{dateBlog}</p>
                    </Col>
                    <Col>
                        <p style={{ textAlign: "right", color: "#8d97ad" }}>{legendBlog}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 style={{ fontWeight: "bold", color: "#d19429" }}>{titleBlog}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12}>
                        {showFullText ? (
                            <p>{textBlog}</p>
                        ) : (
                            <p>{truncatedText}</p>
                        )}
                    </Col>
                    <Col data-bss-hover-animate="pulse" style={{ textAlign: "left" }}>
                        {textBlog.length > 100 && (
                            <button className="custom-blog-button" onClick={toggleShowFullText} >
                                {showFullText ? "Lire moins" : "Afficher la suite"}
                            </button>
                        )}
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

export default BlogCard;
