import React from 'react';
import ContentLoader from 'react-content-loader';

const BlogSkeletonLoader = () => (
  <ContentLoader
    speed={2}
    width={350}
    height={400}
    viewBox="0 0 350 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="5" y="5" rx="10" ry="10" width="340" height="195" />
    <rect x="5" y="220" rx="3" ry="3" width="200" height="10" />
    <rect x="5" y="240" rx="3" ry="3" width="340" height="10" />
    <rect x="5" y="260" rx="3" ry="3" width="250" height="10" />
    <rect x="5" y="280" rx="3" ry="3" width="340" height="10" />
    <rect x="5" y="300" rx="3" ry="3" width="150" height="10" />
    <rect x="5" y="320" rx="3" ry="3" width="340" height="10" />
    <rect x="5" y="340" rx="3" ry="3" width="220" height="10" />
    <rect x="5" y="360" rx="3" ry="3" width="340" height="10" />
  </ContentLoader>
);

export default BlogSkeletonLoader;
