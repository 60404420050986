import React, { useState, useEffect } from 'react';
import Logo from '../../logo.png';

import { Container, Nav, Navbar, NavDropdown, Image } from 'react-bootstrap';

import { NavLink } from 'react-router-dom';

function NavbarMenu() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // const [isFullScreen, setIsFullScreen] = useState(false);

  // const toggleFullScreen = () => {
  //   setIsFullScreen(!isFullScreen);
  // };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = () => {
    setExpanded(false);
  };

  useEffect(() => {
    // Add scroll event listener
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Update state based on scroll position
      setIsScrolled(scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const stopDropdownHover = () => {
  //   setActiveDropdown(null);
  // };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={`bg-body-tertiary custom-navbar ${isScrolled ? 'scrolled' : ''}`}
      fixed="top"
      expanded={expanded}
      onToggle={handleToggle}
          
    >
      <Container>
        {/* Logo outside of responsive-navbar-nav 
        className={`${isFullScreen ? 'full-screen-toggle' : ''}`}
        */}
        <Navbar.Brand as={NavLink} to="/" onClick={handleLinkClick} className="d-lg-none">
          <Image src={Logo} rounded className="logo-navbar-mobile" />
        </Navbar.Brand>

        {/* Navbar Toggle Button */}
        <Navbar.Toggle 
          aria-controls="responsive-navbar-nav"
          className={`ms-auto custom-navbar-toggle`}
          // onClick={toggleFullScreen}
        >
          <Image
            id="menu-actif"
            className="icon-hamburger"
            src="images/icon.png"
            alt="Icon menu hamburger"
          />
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link 
              as={NavLink} 
              to="/" 
              className="nav-link"
              onClick={handleLinkClick}
            >
              ACCUEIL
            </Nav.Link>
            <Nav.Link
              as={NavLink} 
              to="/QuiNousSommes"
              className="nav-link pl-2"
              onClick={handleLinkClick}
            >
              QUI NOUS SOMMES
            </Nav.Link>
            <NavDropdown
              title="SERVICES"
              id="collapsible-nav-dropdown"
              className="mr-2 custom-dropdown-navbar"
            >
              <NavDropdown
                title="Piscine"
                id="dropdown-piscine"
                drop="end"
                className="custom-dropdown-navbar "
              >
                <NavDropdown.Item href="/piscine/service" >Construction </NavDropdown.Item>
                <NavDropdown.Item href="/piscine/devis/none" >Demande de Devis</NavDropdown.Item>
                <NavDropdown.Item href="/piscine/Nos-offres-en-plus" >Nos offres en plus</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Bâtiment"
                id="dropdown-batiment"
                drop="end"
                className="custom-dropdown-navbar "
              >
                <NavDropdown.Item href="/bâtiment/service" >Construction</NavDropdown.Item>
                <NavDropdown.Item href="/bâtiment/devis/none" >Demande de Devis</NavDropdown.Item>
              </NavDropdown>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        {/* Center logo when not in mobile view */}
        <Navbar.Brand as={NavLink} to="/" className="d-none d-lg-block custom-navbar-brand">
          <Image src={Logo} rounded className="logo-navbar" />
        </Navbar.Brand>
        <Navbar.Collapse className="menu-right justify-content-start">
          <Nav>
            <NavDropdown
              title="GALERIE"
              id="collapsible-nav-dropdown"
              className="custom-dropdown-navbar "
            >
              <NavDropdown.Item href="/piscine/galerie" >Piscine</NavDropdown.Item>
              <NavDropdown.Item href="/bâtiment/galerie" >Bâtiment</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link 
              as={NavLink}
              to="/Blog&Actualites"
              className="nav-link "
              onClick={handleLinkClick}
            >
                BLOG & ACTUALITES
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/contact"
              className="nav-link "
              onClick={handleLinkClick}
            >
              CONTACT
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarMenu;
