import React from 'react';
import { Col, Row, Nav, Tab, Accordion, Card } from 'react-bootstrap';

import {
  TitlePage,
  Footer,
  SectionParagraphWithBackground,
} from '../../components';

const ServicePool = () => {

  return (
    <>
      <TitlePage title={"Service Piscine"} />
      <SectionParagraphWithBackground
        image="images/fond-service-pool.jpg"
        title1="CONSTRUCTION"
        title2="CLÉ EN MAIN"
        paragraph={"4A’S Piscine est la première branche de 4A’S Group. Elle est née d'une passion pour le luxe, l'envie de démocratiser l'accès et la rigueur. Nous souhaitons offrir à tous à chacun la possibilité d'acquérir une piscine et les avantages y afférents sans se ruiner."}
      />
      <section className="mt-5 mx-sm-5 mx-1">
        <Row>
          <Col xs={12} className="d-flex">
            <h5
              className="d-flex custom-title5 pt-4"
              style={{ color: '#d19429' }}
            >
              SYSTÈME DE FILTRATION
            </h5>
          </Col><br />
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Card bg="light" className="mb-3" style={{ maxWidth: '50rem', background: 'rgb(255,255,255)' }}>
              <Card.Header className="d-flex" style={{ textAlign: 'center' }}>
                LA PISCINE À DEBORDEMENT
              </Card.Header>
              <Card.Body style={{ minHeight: '200px', background: '#ffffff', textAlign: 'justify' }}>
                <p className="card-text">
                  C'est le débordement en continu de l'eau, mais reste dans un circuit fermé. L'eau du bassin se verse dans une goulotte, récupérée et renvoyée vers le bloc de filtration. Le débordement peut se faire de une ou deux côtés. La meilleure manière pour choisir une piscine à débordement est quand la piscine est sur un terrain en pente ou donnant une vue sur la mer.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col col-6>
            <Card bg="light" className="mb-3" style={{ maxWidth: '50rem' }}>
              <Card.Header className="d-flex" style={{ textAlign: 'center' }}>
                LA PISCINE À SKIMMER
              </Card.Header>
              <Card.Body style={{ minHeight: '200px', background: '#ffffff', textAlign: 'justify' }}>
                <p className="card-text">
                  L'eau passe dans le skimmer pour poursuivre dans la filtration avant de revenir dans le bassin de baignade.&nbsp;<br />
                  4A’S Piscine est la première branche de 4A’S Group. Elle est née d'une passion pour le luxe, l'envie de démocratiser l'accès et la rigueur. Nous souhaitons offrir à tous à chacun la possibilité d'acquérir une piscine
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
      <hr className='custom-hr-2'/>
      <section className="mt-5 mx-sm-5 mx-1">
        <Row>
          <Col xs={12} className="d-flex">
            <h5
              className="d-flex custom-title5 pt-2"
              style={{ color: '#d19429' }}
            >
              TYPE DE PISCINE
            </h5>
          </Col>
        </Row>
      </section>
      <section className="mx-sm-5 mx-1" style={{ background: '' }}>
        <Row>
          <Col>
            <div className="bs-component">
              <Nav className="nav-tabs d-flex" role="tablist">
                <Nav.Item role="presentation" className='custom-nav-item'>
                  <Nav.Link active aria-selected="true" data-bs-toggle="tab" href="#home-1" role="tab" >
                    <span  >ENTERÉE</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item role="presentation" className='custom-nav-item'>
                  <Nav.Link aria-selected="false" data-bs-toggle="tab" href="#profile-1" role="tab" tabindex="-1" >
                    <span  >SEMI-ENTERÉE</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item role="presentation" className='custom-nav-item'>
                  <Nav.Link aria-selected="false" data-bs-toggle="tab" href="#profile-2" role="tab" tabindex="-1" >
                    <span  >HORS SOL</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content id="myTabContent-1" className="tab-content" >
                <Tab.Pane fade show active id="home-1" role="tabpanel" >
                  <p className='pt-2 custom-p-style'>C'est à dire que tout le bassin est en sous-sol. Aucune structure ne se voit. Celle-ci est faite pour les terrains fermés et non remblayés.</p>
                </Tab.Pane>
                <Tab.Pane fade id="profile-1" role="tabpanel" >
                  <p className='pt-2 custom-p-style'>Une partie de la structure est enterrée et une autre est hors-sol. Celle-ci dépend de la profondeur de la piscine et de la qualité du terrain. Elle évite les infiltrations d'eau pouvant fragiliser le bassin.</p>
                </Tab.Pane>
                <Tab.Pane fade id="profile-2" role="tabpanel" >
                  <p className='pt-2 custom-p-style'>Toute la structure du bassin est hors-sol. Elle est obligatoire pour les terrains remblayés ou à nappe phréatique trop accessible. Une pige est nécessaire pour déterminer le type de piscine avant le début de chantier.</p>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Col>
        </Row>
      </section>
      <hr className='custom-hr-2'/>
      <section className="mt-5 mx-sm-5 mx-1">
        <Row>
          <Col xs={12} className="d-flex">
            <h5
              className="d-flex custom-title5 pt-2"
              style={{ color: '#d19429' }}
            >
              REVÊTEMENTS
            </h5>
          </Col>
        </Row>
      </section>
      <section className="mx-sm-5 mx-1">
        <Row>
          <Col>
            <div className="bs-component">
              <Nav className="nav-tabs d-flex" role="tablist2">
                <Nav.Item role="service" className='custom-nav-item'>
                  <Nav.Link active aria-selected="true" data-bs-toggle="tab" href="#service-1" role="tab" >
                    <span  >FIBRE DE VERRE</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item role="service" className='custom-nav-item'>
                  <Nav.Link aria-selected="false" data-bs-toggle="tab" href="#service-2" role="tab" tabindex="-1" >
                    <span  >BÂCHE PVC</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item role="service" className='custom-nav-item'>
                  <Nav.Link aria-selected="false" data-bs-toggle="tab" href="#service-3" role="tab" tabindex="-1" >
                    <span  >ARDOISE</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content id="myTabContent-2" className="tab-content" >
                <Tab.Pane fade show active id="service-1" role="tabpanel" >
                  <p className='pt-2 custom-p-style'>Ce type de revêtement est très étanche donc il ne laisse aucune place à l'infiltration d'eau.
                    Ceci grâce aux couches de mat et le gel coat pour recouvrir la finition.
                    La garantie d'une piscine est de 5 ans avec le revêtement en fibre.</p>
                </Tab.Pane>
                <Tab.Pane fade id="service-2" role="tabpanel" >
                  <p className='pt-2 custom-p-style'>Le revêtement en bâche PVC (0,03mm): d’une couleur blanche,
                    la bâche PVC posée avec colle PVC étanche garantie une étanchéité parfaite à votre Piscine.
                    Les raccords sont renforcés par une soudure qui exclut tout risque de décollement.
                    La fixation sur la partie haute aux abords de la piscine est assurée par des clou en béton placé à 10cm d’intervalle.
                    La bâche PVC à la même adhérence qu’une fibre de verre ou un carreau mosaïque.
                    Notre garantie pour ce type de revêtement est de 03 ans.</p>
                </Tab.Pane>
                <Tab.Pane fade id="service-3" role="tabpanel" >
                  <p className='pt-2 custom-p-style'>Le revêtement en carreau mosaïque donne une image originale à votre piscine.
                    Les carreaux mosaïques proposent plusieurs type, dimension et couleur.
                    Ils sont posés sur les parois intérieur du bassin au-dessus d’une chape en ciment colle spécial pour garantir l’étanchéité.
                    Les carreaux sont posés sans joint.</p>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Col>
        </Row>
      </section>
      <hr className='custom-hr-2'/>
      <section className='mt-5 mx-sm-5 mx-1'>
        <Row className="d-flex">
          <Col>
            <h5
              className="d-flex custom-title6 pt-4"
              style={{ color: '#d19429' }}
            >
              DUREE DE CHANTIER
            </h5>
          </Col>
        </Row>
        <Col><hr /></Col>
        <Row >
          <Col className="offset-0 d-flex">
            <p className="custom-p-style">
              Après le gros œuvre, la plomberie est installée dès J+24, suivie du remplissage et du traitement de l'eau à J+26. Environ 45 jours plus tard, la réception technique est effectuée pour garantir la conformité aux normes et vos attentes. Nous nous engageons à respecter les délais et à assurer la qualité à chaque étape, pour votre satisfaction et la durabilité de votre projet.
            </p>
          </Col>
        </Row>
      </section>
      <hr className='custom-hr-2'/>
      <section className="mt-5 mx-sm-5 mx-1 pb-5">
        <Row className="d-flex">
          <Col className="col-12 offset-0">
            <h5
              className="d-flex custom-title6 pt-4"
              style={{ color: '#d19429' }}
            >
              MODE DE REGLEMENT
            </h5>
          </Col>
          <Col><hr /></Col>
          <Col className="col-12 offset-0 d-flex justify-content-end">
            <p className="custom-p-style">
              Nous proposons différents types de paiement pour nous aligner avec vos possibilités tout en offrant la meilleure en terme de qualité. Le paiement échelonné se fera avec la collaboration de votre banque. Vous pourriez donc choisir entre 2
            </p>
          </Col>
          <Col lg={12} xl={12}>
            <div className="bs-component" >
              <Accordion defaultActiveKey="0" >
                <Accordion.Item eventKey="0" >
                  <Accordion.Header >
                    Paiement au comptant
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="custom-p-style" >
                      Le paiement au comptant c’est le fait de payer l’intégralité en une fois. Mais comme le chantier se divise en 3 grandes étapes, nous ajustons le paiement en fonction. Donc, à la signature il vaut faut payer 50% de la facture finale, 20% à la fin des gros œuvres puis le 10% restant à la réception technique.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" >
                  <Accordion.Header >
                    Paiement échelonné
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="custom-p-style" >
                      Paiement sur 6 mois avec agio
                      Ce paiement se présente comme suit, la moitié de la facture 50% est à payer à la signature du contrat puis vous payerez 10% tous les mois pendant 6 ou 12 mois avec un agio.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
        </Row>
      </section>
      <Footer />
    </>
  );
};

export default ServicePool;
