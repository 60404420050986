import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const ContactCard = () => {
    // Coordonnées de la localisation
    const latitude = -18.9145111;
    const longitude = 47.5378585;

    // Définition de l'icône personnalisée
    const customIcon = new L.Icon({
        iconUrl: 'images/logo.png', // Chemin vers l'icône personnalisée
        iconSize: [32, 32], // Taille de l'icône [largeur, hauteur]
        iconAnchor: [16, 32], // Point d'ancrage de l'icône [position horizontale, position verticale]
    });

    return (
        <section className="informations-contact" id="info">
            <Container fluid>
                <Row className="no-padding" >
                    <Col className='p-0' xs={12} sm={12} md={6} lg={6} style={{ background: "#6a6a6a" }}>
                        <MapContainer className='custom-map-container' center={[latitude, longitude]} zoom={20} style={{ width: '100%' }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.4as.mg/contact">4AS</a> Group'
                            />
                            <Marker position={[latitude, longitude]} icon={customIcon}>
                                <Popup>4A'S GROUP</Popup>
                            </Marker>
                        </MapContainer>
                    </Col>
                    <Col sm={12} md={6} lg={6} className="d-flex justify-content-center py-4" style={{ background: '#d3861c' }}>
                        <div className="mt-sm-5 mb-sm-5">
                            <p className="text-white" style={{ fontWeight: 'bold', fontSize: '29px' }}>Siège Social</p>
                            <p className="text-white">4A'S Madagascar<br />Bureau Immeuble le Colisée Ampasanimalo, box 29</p>
                            <p className="text-white"> Tel : +261 34 70 709 09<br /> Email : 4as@4as.mg </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ContactCard;
