// NotFound.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import {
    TitlePage,
    Footer,
} from '../../components';

const NotFound = () => {
  return (
    <>
    <TitlePage title={"Page introuvable"}/>
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <div className="mt-5 text-center">
            <h1 className="mt-5 display-4">404 - Page introuvable</h1>
            <p className="lead">Désolé, la page que vous recherchez n'existe pas.</p>
          </div>
        </Col>
      </Row>
    </Container>
    <Footer />
    </>
  );
};

export default NotFound;
