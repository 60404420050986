import React from "react";
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import GalleryCardBuildingSkeletonLoader from "./GalleryCardBuildingSkeletonLoader";

const generateSkeletonColumns = (count) => {
    const skeletonColumns = [];
    for (let i = 0; i < count; i++) {
        skeletonColumns.push(
            <Col key={i} lg={4} xs={12} className="d-flex justify-content-center">
                <div className="ml-5 bg-white mb-2 text-center" style={{ borderRadius: "15px" }}>
                    <GalleryCardBuildingSkeletonLoader />
                </div>
            </Col>
        );
    }
    return skeletonColumns;
};


const GalleryCardBuilding = ({ isLoading, idBuilding, imageBuilding, nameBuilding, priceBuilding, descriptionBuilding }) => {

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    if (isLoading) {
        return <Row>{generateSkeletonColumns(3)}</Row>;
    }

    return (
        <Col key={idBuilding} className="custom-gallery-col custom-gallery-col-2" id={`building${idBuilding}`}  style={{ marginRight: '-7px', marginLeft: '-7px' }}>
            <div className="content">
                <h4 className="custom-gallery-h4" style={{ display: 'block' }}>
                    <strong>{nameBuilding}</strong><br/>
                    <strong className="custom-gallery-price-2">{formatPrice(priceBuilding)} AR TTC</strong> <br/>
                </h4>
                <p className="custom-gallery-description" style={{ display: 'block' }}>
                    <br/>{descriptionBuilding} <br/>
                     <Link className="text-decoration-none" to={`/bâtiment/devis/${idBuilding}`}>
                        <span className="custom-gallery-a">Plus d'information</span>
                    </Link>
                </p>
            </div>
            <img className="custom-gallery-img" src={imageBuilding} alt={nameBuilding} style={{ width: '110%' }} />
        </Col>
    );
};

export default GalleryCardBuilding;
